











































import { Component, Prop, Mixins } from 'vue-property-decorator';

import ContainerSidebar from '@/components/ContainerSidebar/ContainerSidebar.vue';
import Book from '@/components/Book/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';
import PlanUpdateSidebar from '@/components/PlanUpdateSidebar/index.vue';
import BookSidebarLoading from '@/components/BookSidebarLoading/index.vue';

import SummaryPlanner from '../SummaryPlanner/SummaryPlanner.vue';

import store from '@/store';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';

import permission from '@/mixins/permission';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';
import { LIST_PERMISSION } from '@/constant/ListPermission';
import { URL_QUERO_ASSINAR_PLANO_PLANNER } from '@/constant/FreePlanUrls';

@Component({
  components: {
    ContainerSidebar,
    Book,
    SummaryPlanner,
    ContainerFluid,
    PlanUpdateSidebar,
    BookSidebarLoading
  }
})
export default class PlannerSidebar extends Mixins(permission) {
  @Prop() router!: any;
  @Prop({ default: () => ({}) }) infoWeekPlanner!: Record<string, any>;
  @Prop({ default: false }) hasPermissionPlanner!: boolean;

  private URL_QUERO_ASSINAR_PLANO = URL_QUERO_ASSINAR_PLANO_PLANNER;

  get isPermission() {
    return this.can(LIST_PERMISSION.PLANNER);
  }

  get isPlanFree() {
    return store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get isInfoWeekPlanner() {
    return Object.entries(this.infoWeekPlanner).length;
  }

  handleRemakePlanner() {
    this.setTrackAmplitude();

    this.router.push({ name: 'PlannerConfig' });
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'PlannerSidebar',
          local: 'Refazer meu planner'
        }
      }
    });
  }
}
