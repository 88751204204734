























import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

@Component({})
export default class ListWeekDays extends Vue {
  @Prop() listWeekday!: Array<Record<string, any>>;
  @Prop() indexActive!: number;

  private indexActiveCurrentDay: number | null = null;

  @Watch('indexActive', {
    immediate: true
  })
  saveIndexCurrentDay() {
    if (!this.indexActiveCurrentDay) this.indexActiveCurrentDay = this.indexActive;
  }

  checkCurrentWeek(index: number) {
    return this.indexActiveCurrentDay !== this.indexActive && this.indexActiveCurrentDay === index;
  }

  setTitle(weekday: Date) {
    const fullTitle = format(weekday, 'cccc', {
      locale: pt
    });

    const title = fullTitle.split('-');
    return `${title[0].substr(0, 1).toUpperCase()}${title[0].slice(1)}`;
  }

  setMonth(weekday: Date) {
    const dayOfMonth = format(weekday, 'dd/LL', {
      locale: pt
    });

    return dayOfMonth;
  }

  isCurrentDate(weekday: Date) {
    if (new Date() === weekday) return true;
    return false;
  }

  handleWeekDay(value: number) {
    this.$emit('day-week', value);
  }
}
