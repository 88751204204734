

















































































































import {
  Component, Vue, Watch, Mixins 
} from 'vue-property-decorator';
import {
  format,
  eachDayOfInterval,
  differenceInDays,
  startOfWeek,
  endOfWeek,
  isAfter
} from 'date-fns';

import PageHeader from '@/components/PageHeader/PageHeader.vue';
import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import Footer from '@/components/Footer/Footer.vue';
import SubjectInfo from '@/components/Subject/SubjectInfo/index.vue';
import SubjectBox from '@/components/Subject/SubjectBox/index.vue';
import ListAccordionLoading from '@/components/ListAccordionLoading/index.vue';
import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import FeedbackPermissionPlanner from './components/FeedbackPermissionPlanner/index.vue';
import PlannerAccordion from './components/PlannerAccordion/PlannerAccordion.vue';
import ListWeekDays from './components/ListWeekDays/index.vue';
import Sidebar from './components/PlannerSidebar/PlannerSidebar.vue';

import SetSidebar from '@/mixins/SetSidebar';
import permission from '@/mixins/permission';
import PlannerData from '@/mixins/PlannerData';

import { setNumber } from '@/share/Util/UsefulFunctions/UserfulFunctions';

import { LIST_PERMISSION } from '@/constant/ListPermission';

const TITLE_NEXT_WEEK_PERMISSION = 'Semanas a serem liberadas';
const TITLE_NEXT_WEEK_NOT_PERMISSION = 'Semanas bloqueadas';

@Component({
  components: {
    PageHeader,
    BoxContainer,
    PlannerAccordion,
    SubjectBox,
    SubjectInfo,
    ListWeekDays,
    Footer,
    FeedbackPermissionPlanner,
    ListAccordionLoading,
    ContainerFluid
  },
  filters: { setNumber }
})
export default class Planner extends Mixins(SetSidebar, permission, PlannerData) {
  private isMobile: boolean = this.$responsive.isMobile;

  private listWeekPlanner: Record<string, any> = {};
  private listOldWeek: Array<Record<string, any>> = [];
  private currentWeek: Array<Record<string, any>> = [];
  private listNextWeek: Array<Record<string, any>> = [];

  private isLoading = false;
  private showFeedbackPermission = false;
  private showAllNextWeek = false;

  private indexDayActiveWeek = -1;
  private quantityAulas = 0;

  created() {
    this.verifyPermissionPlanner();
  }

  mounted() {
    this.onMobileChange();
    this.setSideBook();
    this.setBreadCrumbs();
  }

  beforeDestroy() {
    this.$store.commit('setSidebar', false);
  }

  get getNextWeek() {
    if (this.showAllNextWeek) {
      return this.listNextWeek;
    }
    return this.listNextWeek.slice(0, 3);
  }

  get getOldWeek() {
    return this.listOldWeek;
  }

  get getCurrentWeek() {
    return this.currentWeek[0];
  }

  get hasPermission() {
    return this.can(LIST_PERMISSION.PLANNER);
  }

  get findPermissionPlanner() {
    return this.findPermission(LIST_PERMISSION.PLANNER);
  }

  get setTitleNextWeek() {
    return this.hasPermission ? TITLE_NEXT_WEEK_PERMISSION : TITLE_NEXT_WEEK_NOT_PERMISSION;
  }

  verifyPermissionPlanner() {
    if (this.hasPermission) this.getPlannerWeek();
    else this.showFeedbackPermission = true;
  }

  redirectPlannerConfig() {
    this.$router.push({
      name: 'PlannerConfig'
    });
  }

  async setUpdateProfile(profile: Record<string, any>) {
    await this.$store.dispatch('setProfile', profile);
  }

  @Watch('indexDayActiveWeek')
  storeLoaderData() {
    if (this.currentWeek && this.currentWeek[0] && Object.entries(this.currentWeek[0]).length) {
      const week = this.currentWeek[0]?.grid && this.currentWeek[0]?.grid[this.indexDayActiveWeek];
      this.$store.dispatch('loaderData', { index: this.indexDayActiveWeek, week });
    }
  }

  @Watch('currentWeek', {
    immediate: true
  })
  setIndexDayActive() {
    if (this.currentWeek.length) {
      const date = new Date();
      const dataWeekInitial = new Date(this.currentWeek[0]?.end);
      const dateInitial = startOfWeek(dataWeekInitial, { weekStartsOn: 0 });

      const index = differenceInDays(date, dateInitial);

      this.indexDayActiveWeek = index;
    }
  }

  setWeekDay(value: number) {
    if (value === this.indexDayActiveWeek) return;
    this.indexDayActiveWeek = value;
  }

  nameWeek(dateInitial: string, dateFinish: string) {
    const numberIndex = this.listWeekPlanner?.weeks.findIndex(
      (week: Record<string, any>) => week?.init === dateInitial && week?.end === dateFinish
    );
    const dateInitialOfStart = startOfWeek(new Date(dateFinish), { weekStartsOn: 0 });
    const dateFinishOfWeek = endOfWeek(new Date(dateFinish), { weekStartsOn: 0 });
    const formatDataInitial = format(new Date(dateInitialOfStart), 'dd/MM');
    const formatDataFinish = format(new Date(dateFinishOfWeek), 'dd/MM');

    return `SEMANA ${setNumber(numberIndex + 1)} (${formatDataInitial}-${formatDataFinish})`;
  }

  setQuantityTopics(data: Record<string, any>, type: string, initialValue: number) {
    let quantityFiles: number = initialValue;

    if (data[type] && data[type].length > 0) {
      quantityFiles = data[type].length;
      return quantityFiles;
    }

    if (data.topics && data.topics.length > 0) {
      const total = quantityFiles;
      data.topics.forEach((topic: Record<string, any>) => {
        quantityFiles += this.setQuantityTopics(topic, type, total);
      });
    }
    return quantityFiles;
  }

  setListInfo(weeks: Array<Array<Record<string, any>>>) {
    let quantityAulas = 0;
    let quantityArchives = 0;
    const quantityExercise = 0;

    const localWeeks = weeks?.forEach((week: Array<Record<string, any>>) => {
      week.forEach((discipline: Record<string, any>) => {
        let totalAulas = 0;
        let totalArchives = 0;
        // let totalExercise = 0;
        totalAulas = this.setQuantityTopics(discipline, 'videos', totalAulas);
        totalArchives = this.setQuantityTopics(discipline, 'archives', totalArchives);
        // totalExercise += this.setQuantityTopics(discipline, 'exercices', totalExercise);
        quantityAulas += totalAulas;
        quantityArchives += totalArchives;
        // quantityExercise += totalExercise;
      });
    });

    return [
      {
        quantity: quantityAulas,
        title: 'Aulas'
      },
      {
        quantity: quantityExercise,
        title: 'Exercícios'
      },
      {
        quantity: quantityArchives,
        title: 'Materiais'
      }
    ];
  }

  async getPlannerWeek() {
    this.isLoading = true;
    try {
      const response = await this.PlannerService.getPlanner();

      if (response?.weeks && response?.weeks.length) {
        this.showFeedbackPermission = false;
        this.listWeekPlanner = response;

        this.listOldWeek = this.filterOldWeek(response?.weeks);

        const currentWeekFilter = this.filterCurrentWeek(response?.weeks);

        const processGrid = this.processingPlanner(currentWeekFilter[0]);

        this.currentWeek = [processGrid];
        this.listNextWeek = this.filterNextWeek(response?.weeks);
        return;
      }

      if (response.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_FORBIDDEN) {
        this.showFeedbackPermission = true;
        return;
      }

      this.$router.push({
        name: 'PlannerConfig'
      });
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o Planner.',
        status: 'error'
      });
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  filterOldWeek(listWeek: Array<Record<string, any>>) {
    const currentDate = new Date();

    const currentClass = listWeek.filter((classWeek: Record<string, any>) => {
      const endDate = new Date(classWeek?.end);
      endDate.setHours(23, 59, 59, 0);

      return endDate < currentDate;
    });

    return currentClass;
  }

  filterNextWeek(listWeek: Array<Record<string, any>>) {
    const currentDate = format(new Date(), 'MM/dd/yyyy');
    const nextClass = listWeek.filter((classWeek: Record<string, any>) => {
      const initDate = format(new Date(classWeek?.init), 'MM/dd/yyyy');
      return isAfter(new Date(initDate), new Date(currentDate));
    });

    return nextClass;
  }

  processingPlanner(currentWeek: Record<string, any>) {
    const newWeek: Record<string, any> = currentWeek;

    const localCurrentWeek = currentWeek?.grid?.forEach(
      (week: Record<string, any>, indexWeek: number) => {
        newWeek.grid[indexWeek] = this.dataProcessingPlanner(currentWeek.grid[indexWeek]);
      }
    );

    return newWeek;
  }

  dataProcessingPlanner(week: Array<Record<string, any>>) {
    const newWeek: Array<Record<string, any>> = [];

    week.forEach((discipline: Record<string, any>, indexDiscipline: number) => {
      newWeek[indexDiscipline] = this.dataProcessingPlannerTopic(week[indexDiscipline]);
    });

    return newWeek;
  }

  dataProcessingPlannerTopic(topic: Record<string, any>) {
    const newTopic = topic;
    if (newTopic?.topicId) {
      const topicID = newTopic.topicId;
      const videos = newTopic.videoTeoric;

      newTopic.idTopic = topicID;
      newTopic.videos = videos;

      delete newTopic.topicId;
      delete newTopic.videoTeoric;
    }

    if (newTopic.topics && newTopic.topics.length > 0) {
      newTopic.topics.forEach((topicItem: Record<string, any>) => {
        this.dataProcessingPlannerTopic(topicItem);
      });
    }

    return newTopic;
  }

  setNextWeek() {
    this.showAllNextWeek = !this.showAllNextWeek;
  }

  setListWeekDay(week: Record<string, any>) {
    const dateInitial = startOfWeek(new Date(week?.end), { weekStartsOn: 0 });
    const dateFinal = endOfWeek(new Date(week?.end), { weekStartsOn: 0 });

    const listDate = eachDayOfInterval({
      start: dateInitial,
      end: dateFinal
    });

    return listDate;
  }

  @Watch('$responsive.isMobile')
  onMobileChange() {
    this.isMobile = this.$responsive.isMobile;
    this.$store.commit('setSidebar', !this.isMobile);
  }

  setBreadCrumbs() {
    this.$breadcrumb.set([{ title: 'planner', to: '/planner' }]);
  }

  @Watch('showFeedbackPermission')
  @Watch('listWeekPlanner')
  @Watch('$route.params.path')
  setSideBook() {
    const ComponentClass = Vue.extend(Sidebar);
    const instance = new ComponentClass({
      propsData: {
        router: this.$router,
        infoWeekPlanner: this.listWeekPlanner,
        hasPermissionPlanner: this.hasPermission && !this.showFeedbackPermission
      }
    });

    this.setSidebar(instance);
  }
}
